import {useNavigate, useParams} from "react-router-dom";
import ProjectData from "../components/data/ProjectData";
import {BsStack} from "react-icons/bs";
import {MdFeaturedPlayList, MdOutlineKeyboardDoubleArrowRight, MdScreenshotMonitor} from "react-icons/md";
import 'devicon';
import FadeIn from "react-fade-in";
import {useEffect} from "react";
import {FaCode, FaHome, FaLink} from "react-icons/fa";
import {Image} from "@chakra-ui/react";
import CodeBlock from "../components/CodeBlock";

export default function ViewProject() {
    const {projectName} = useParams();
    const navigate = useNavigate();
    const project = ProjectData.slice().filter((project) => project.urlName).find((project) => project.urlName.toLowerCase() === projectName.toLowerCase());

    const navigateBack = () => {
        if (window.length) {
            window.history.back();
        } else {
            navigate('/');
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [projectName]);

    if (!project) {
        navigate('/');
        return;
    }

    return (
        <>
            <div className="hero min-h-screen bg-no-repeat bg-cover bg-fixed"
                 style={{backgroundImage: "url('/background.svg')"}}>
                <div className="hero-overlay bg-opacity-0 backdrop-blur-sm"></div>
                <div className="hero-content text-neutral-content mb-auto">
                    <div className="py-10 pt-5 relative z-10">
                        <FadeIn>
                            <button
                                onClick={() => navigateBack()}
                                className="btn btn-sm glass hover:btn-primary text-white font-light">
                                <FaHome/>
                                Back to Homepage
                            </button>
                        </FadeIn>

                        <FadeIn transitionDuration={600} className="container mx-auto md:px-5 space-y-5">
                            <h2 className="text-center text-5xl font-bold mt-3 text-white">
                                {project.name}
                            </h2>

                            <div className="flex flex-col justify-center items-center">
                                <div className="w-40 h-1 bg-gradient-to-r from-fuchsia-500 to-pink-500 rounded"></div>
                            </div>

                            <Image
                                className="mx-auto rounded-box max-w-xs lg:max-w-screen-sm xl:max-w-screen-md"
                                src={project.preview}
                                alt="Preview"/>

                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                                {/* About Section */}
                                <div className="mb-8 lg:mb-0 lg:block col-span-2 lg:col-span-1">
                                    <h2 className="text-3xl font-semibold mb-2 text-white">About</h2>
                                    <p className="text-lg text-white">{project.description}</p>
                                </div>

                                {/* Stack Section */}
                                <div className="mb-8 lg:mb-0 lg:ml-auto">
                                    <h2 className="text-3xl font-semibold mb-2 inline-flex items-center text-white">
                                        <BsStack className="mr-3"/>
                                        Stack
                                    </h2>
                                    <div
                                        className="flex flex-wrap items-center gap-2.5 lg:gap-3.5 text-white w-fit">
                                        {project.stack.map((icon, index) => (
                                            <i key={index} className={`devicon-${icon} text-4xl`}></i>
                                        ))}
                                    </div>
                                </div>

                                {/* Features Section */}
                                {project.features && project.features.length > 0 && (
                                    <div className="col-span-2">
                                        <h2 className="text-3xl font-semibold mb-2 inline-flex items-center text-white">
                                            <MdFeaturedPlayList className="mr-3"/>
                                            Features
                                        </h2>
                                        <div className="list-disc list-inside text-lg text-white">
                                            {project.features.map((feature, index) => (
                                                <span key={index} className="flex">
                                            <MdOutlineKeyboardDoubleArrowRight className="w-6 h-6 mr-1"/>
                                                    {feature}
                                        </span>
                                            ))}
                                        </div>
                                    </div>
                                )}

                                {project.buttons && (
                                    <div className="col-span-2">
                                        <h2 className="text-3xl font-semibold mb-2 inline-flex items-center text-white">
                                            <FaLink className="mr-3"/>
                                            Associated Link
                                        </h2>
                                        <div className="flex flex-wrap gap-3">
                                            {project.buttons && (
                                                project.buttons.map((btn, index) => (
                                                    <div>
                                                        {/*{btn.note && (*/}
                                                        {/*    <p className="mb-2 text-red-500"><b>หมายเหตุ</b> {btn.note}</p>*/}
                                                        {/*)}*/}
                                                        <a className="btn btn-ghost glass rounded-full font-light text-white shadow-md bg-gradient-to-br from-indigo-400 to-cyan-400"
                                                           href={btn.link}
                                                           target="_blank" rel="noreferrer">
                                                            {btn.label}
                                                        </a>
                                                    </div>
                                                ))
                                            )}
                                        </div>
                                    </div>
                                )}

                                {project.videos && (
                                    <div className="col-span-2">
                                        <h2 className="text-3xl font-semibold mb-2 inline-flex items-center text-white">
                                            <MdScreenshotMonitor className="mr-3"/>
                                            Videos
                                        </h2>
                                        <div className="flex flex-wrap justify-center gap-7">
                                            {project.videos.map((vid, index) => (
                                                <div key={index}>
                                                    <div className="w-fit">
                                                        <h2 className="text-xl mb-1">{vid.title}</h2>
                                                        <div
                                                            className="w-full h-1 bg-gradient-to-r from-fuchsia-500 to-pink-500 rounded mb-2.5"></div>
                                                    </div>
                                                    <div className="rounded object-cover max-w-xs lg:max-w-screen-lg">
                                                        <iframe
                                                            width="100%"
                                                            height="100%"
                                                            src={vid.path}
                                                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                                                        >
                                                        </iframe>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}

                                {project.images && (
                                    <div className="col-span-2">
                                        <h2 className="text-3xl font-semibold mb-2 inline-flex items-center text-white">
                                            <MdScreenshotMonitor className="mr-3"/>
                                            Screenshots
                                        </h2>
                                        <div className="flex flex-wrap justify-center gap-7">
                                            {project.images.map((image, index) => (
                                                <img
                                                    key={index}
                                                    loading="lazy"
                                                    className="rounded object-cover max-w-xs lg:max-w-screen-lg"
                                                    src={image}
                                                    alt="Project Screenshot"/>
                                            ))}
                                        </div>
                                    </div>
                                )}

                                {project.codes && project.codes.length > 0 && (
                                    <div className="col-span-2">
                                        <h2 className="text-3xl font-semibold mb-4 inline-flex items-center text-white">
                                            <FaCode className="mr-2"/>
                                            Source Code
                                        </h2>
                                        <div className="space-y-10">
                                            {project.codes.map((code, index) => (
                                                <CodeBlock key={index} lang={code.lang} codePath={code.path}/>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </FadeIn>
                    </div>
                </div>
            </div>
            {/*<section className="relative bg-no-repeat min-h-screen bg-cover"*/}
            {/*         style={{backgroundImage: `url('/background.svg')`}}>*/}
            {/*    <div className="absolute inset-0 backdrop-blur bg-opacity-0 bg-gray-800"></div>*/}
            {/*    <div className="mx-5 py-10 pt-5 relative z-10">*/}
            {/*        <FadeIn>*/}
            {/*            <button*/}
            {/*                onClick={() => navigateBack()}*/}
            {/*                className="btn btn-sm glass hover:btn-primary text-white font-light">*/}
            {/*                <FaHome/>*/}
            {/*                Back to Homepage*/}
            {/*            </button>*/}
            {/*        </FadeIn>*/}

            {/*        <FadeIn transitionDuration={600} className="container mx-auto md:px-5 space-y-5">*/}
            {/*            <h2 className="text-center text-5xl font-bold mt-3 text-white">*/}
            {/*                {project.name}*/}
            {/*            </h2>*/}

            {/*            <div className="flex flex-col justify-center items-center">*/}
            {/*                <div className="w-40 h-1 bg-gradient-to-r from-fuchsia-500 to-pink-500 rounded"></div>*/}
            {/*            </div>*/}

            {/*            <Image*/}
            {/*                className="mx-auto rounded-box max-w-xs xl:max-w-screen-md"*/}
            {/*                src={project.preview}*/}
            {/*                alt="Preview"/>*/}

            {/*            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">*/}
            {/*                /!* About Section *!/*/}
            {/*                <div className="mb-8 lg:mb-0">*/}
            {/*                    <h2 className="text-3xl font-semibold mb-2 text-white">About</h2>*/}
            {/*                    <p className="text-lg text-white">{project.description}</p>*/}
            {/*                </div>*/}

            {/*                /!* Stack Section *!/*/}
            {/*                <div className="mb-8 lg:mb-0 ml-auto">*/}
            {/*                    <h2 className="text-3xl font-semibold mb-2 inline-flex items-center text-white">*/}
            {/*                        <BsStack className="mr-3"/>*/}
            {/*                        Stack*/}
            {/*                    </h2>*/}
            {/*                    <div*/}
            {/*                        className="grid grid-cols-3 lg:grid-cols-5 items-center gap-2.5 lg:gap-3.5 text-white w-fit">*/}
            {/*                        {project.stack.map((icon, index) => (*/}
            {/*                            <i key={index} className={`devicon-${icon} text-4xl`}></i>*/}
            {/*                        ))}*/}
            {/*                    </div>*/}
            {/*                </div>*/}

            {/*                /!* Features Section *!/*/}
            {/*                {project.features && project.features.length > 0 && (*/}
            {/*                    <div className="col-span-2">*/}
            {/*                        <h2 className="text-3xl font-semibold mb-2 inline-flex items-center text-white">*/}
            {/*                            <MdFeaturedPlayList className="mr-3"/>*/}
            {/*                            Features*/}
            {/*                        </h2>*/}
            {/*                        <div className="list-disc list-inside text-lg text-white">*/}
            {/*                            {project.features.map((feature, index) => (*/}
            {/*                                <span key={index} className="flex">*/}
            {/*                                <MdOutlineKeyboardDoubleArrowRight className="w-6 h-6 mr-1"/>*/}
            {/*                                    {feature}*/}
            {/*                            </span>*/}
            {/*                            ))}*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                )}*/}

            {/*                {project.images && (*/}
            {/*                    <div className="col-span-2">*/}
            {/*                        <h2 className="text-3xl font-semibold mb-2 inline-flex items-center text-white">*/}
            {/*                            <MdScreenshotMonitor className="mr-3"/>*/}
            {/*                            Screenshots*/}
            {/*                        </h2>*/}
            {/*                        <div className="flex flex-wrap justify-center gap-7">*/}
            {/*                            {project.images.map((image, index) => (*/}
            {/*                                <img*/}
            {/*                                    key={index}*/}
            {/*                                    className="rounded object-cover max-w-xs lg:max-w-screen-sm"*/}
            {/*                                    src={image}*/}
            {/*                                    alt="Project Screenshot"/>*/}
            {/*                            ))}*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                )}*/}

            {/*                {project.codes && project.codes.length > 0 && (*/}
            {/*                    <div className="col-span-2">*/}
            {/*                        <h2 className="text-3xl font-semibold mb-4 inline-flex items-center text-white">*/}
            {/*                            <FaCode className="mr-2"/>*/}
            {/*                            Source Code*/}
            {/*                        </h2>*/}
            {/*                        <div className="space-y-10">*/}
            {/*                            {project.codes.map((code, index) => (*/}
            {/*                                <CodeBlock key={index} lang={code.lang} codePath={code.path}/>*/}
            {/*                            ))}*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                )}*/}
            {/*            </div>*/}
            {/*        </FadeIn>*/}
            {/*    </div>*/}
            {/*</section>*/}
        </>
    )
}