import './index.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import {ChakraProvider} from "@chakra-ui/react";
import '@fontsource/prompt';
import ViewProject from "./pages/ViewProject";
import FooterBar from "./components/Footer";
import ScrollUpButton from "./components/ScrollUpButton";
import NotFound from "./pages/NotFound";

function App() {
    return (
        <ChakraProvider>
            <BrowserRouter>
                <main className="bg-black text-white min-h-screen overflow-x-hidden">
                    <Routes>
                        <Route path={"*"} element={<NotFound/>}/>
                        <Route path={"/"} element={<Home/>}/>
                        <Route path="/project/:projectName" element={<ViewProject/>}/>
                    </Routes>
                    <FooterBar/>
                </main>
                <ScrollUpButton/>
            </BrowserRouter>
        </ChakraProvider>
    );
}

export default App;
