import {useNavigate} from "react-router-dom";

export default function NotFound() {
    const navigate = useNavigate();

    const goHome = () => {
        navigate('/');
    }

    return (
        <div className="flex flex-col justify-center items-center min-h-screen">
            <h2 className="text-6xl mb-2">404</h2>
            <p className="italic mb-5">Page not found</p>
            <button
                onClick={goHome}
                className="btn btn-secondary glass font-light uppercase"
            >
                Back to Homepage
            </button>
        </div>
    )
}