import FadeIn from "react-fade-in";

export default function WelcomeSection() {

    const scrollProjectSection = () => {
        const targetDiv = document.getElementById('project');

        if (targetDiv) {
            targetDiv.scrollIntoView({behavior: 'smooth'});
        }
    };

    return (
        <section className="hero min-h-screen">
            <div className="hero-content flex-col lg:flex-row-reverse px-5 lg:px-14 xl:px-16">
                <FadeIn transitionDuration={1000} className="text-center">
                    <div className="inline-flex">
                        <h1 className="text-5xl font-bold inline-block p-1 bg-gradient-to-tr from-indigo-400 via-purple-300 to-blue-500 text-transparent bg-clip-text">
                            Jozho
                        </h1>
                    </div>
                    <p className="py-6">Learning from the past to create the future with technology.</p>
                    <FadeIn delay={400} className="flex justify-center">
                        <button
                            onClick={scrollProjectSection}
                            className="btn btn-wide btn-ghost rounded-full glass text-lg text-white bg-gradient-to-r from-rose-400 via-fuchsia-500 to-indigo-500 uppercase">
                            Get Started
                        </button>
                    </FadeIn>
                </FadeIn>
            </div>
        </section>
    )
}