const ProjectData = [
    {
        urlName: "dyndine",
        name: "DynDine",
        description:
            "เว็บแอปพลิเคชันสำหรับร้านอาหารนี้ถูกออกแบบขึ้นเพื่อให้เจ้าของร้านและผู้จัดการร้านอาหารสามารถบริหารจัดการธุรกิจร้านอาหารได้อย่างคล่องแคล่ว",
        features: [
            "ระบบสามารถบันทึกรายการอาหารและคำนวณราคาของลูกค้าแต่ละโต๊ะได้",
            "ระบบสามารถพิมพ์ใบเสร็จ (พร้อม QR Code) ได้",
            "ระบบสามารถบันทึกรายการอาหารตามหมวดหมู่ได้",
            "ระบบสามารถบันทึกค่าใช้จ่ายรายวันได้",
            "ระบบสามารถจัดการพนักงานและเงินเดือนได้",
            "ระบบสามารถตัดสต๊อกอัตโนมัติได้",
            "ระบบสามารถแจ้งเตือนหากสต๊อกหมด",
            "ระบบสามารถแสดงรายงานสรุปสุทธิแบบ รายวัน รายเดือน รายปี ได้",
            "ระบบสามารถแสดงรายงานสินค้าขายดีได้",
        ],
        stack: [
            "java-plain",
            "jquery-plain",
            "javascript-plain",
            "tailwindcss-plain",
            "mysql-plain",
            "tomcat-line",
            "docker-plain",
        ],
        preview: "/img/dyndine/home.png",
        images: [
            "/img/dyndine/view_table.png",
            "/img/dyndine/expense.png",
            "/img/dyndine/today_sum.png",
            "/img/dyndine/month_sum.png",
            "/img/dyndine/overall.png",
            "/img/dyndine/order_search.png",
            "/img/dyndine/view_order.png",
            "/img/dyndine/view_stock_1.png",
            "/img/dyndine/view_stock_2.png",
            "/img/dyndine/employee.png",
            "/img/dyndine/menu_manage.png",
            "/img/dyndine/stock_manage.png",
        ],
        buttons: [
            {label: "Visit Website", link: "https://dyndine.jozho.space/"},
        ],
        customers: [
            {label: "บ้านไอริณ", link: "https://www.facebook.com/BAANIRIN"},
        ],
        codes: [
            {path: 'example-code/dyndine-1.txt', lang: 'javascript'},
            {path: 'example-code/dyndine-2.txt', lang: 'sql'},
        ]
    },
    {
        urlName: "kasia",
        name: "Kasia",
        description:
            "Spigot Plugin สำหรับเซิร์ฟเวอร์ Minecraft ที่ถูกออกแบบมาเพื่อให้ประสบการณ์เล่นเกมไม่ซ้ำซากและท้าทายมากขึ้น โดยเฉพาะสำหรับผู้เล่นที่ต้องการสัมผัสกับโลก MMORPG ในตัวเกม เสริมเติมความสนุกและความหลากหลายในการผจญภัย",
        features: [
            "ระบบเก็บเลเวล (Levelling System)",
            "ระบบเพิ่มค่าพลัง (Stats System)",
            "ระบบการต่อสู้ (Custom Combat Mechanics)",
            "ระบบธนาคาร",
            "ระบบสายอาชีพ (Class System)",
            "โลกสำหรับฟาร์มเลเวลและวัตถุดิบที่ไม่มีใน Vanilla (ตัวเกมดั้งเดิม)",
            "ระบบปาร์ตี้ (แบ่งปันค่าประสบการณ์ให้กับเพื่อนร่วมทีม)",
            "ระบบตีบวก",
            "ระบบคืนจันทร์สีเลือด (Bloodmoon Event)",
        ],
        stack: ["java-plain"],
        preview: "/img/kasia/preview.png",
        videos: [
            {path: "https://streamable.com/e/s0fgny", title: 'Custom Menus'},
            {path: "https://streamable.com/e/ul97zi", title: 'Stat Upgrades'},
            {path: "https://streamable.com/e/gowspc", title: 'Knight Preview'},
            {path: "https://streamable.com/e/a30ubn", title: 'Striker Preview'},
            {path: "https://streamable.com/e/r31zsk", title: 'Archer Preview'},
            {path: "https://streamable.com/e/ul84kd", title: 'Healer Preview'},
            {path: "https://streamable.com/e/8mfd7g", title: 'Merchant Preview'},
        ],
        codes: [
            {path: 'example-code/kasia-1.txt', lang: 'java'},
        ]
    },
    {
        urlName: "mask-detection",
        name: "Face Mask Detection",
        description:
            "วิเคราะห์รูปภาพตรวจสอบการสวมหน้ากากอนามัยโดยใช้เทคโนโลยี Machine Learning ที่ได้รับการฝึกฝนจาก Roboflow และใช้โมเดล YOLOv5 ร่วมกับ Flask ในการพัฒนาเว็บแอปพลิเคชัน",
        features: ["อัพโหลดรูปภาพเพื่อวิเคราะห์", "แสดงผลลัพธ์พร้อม Confidence Score"],
        stack: [
            "python-plain",
            "opencv-plain",
            "pytorch-plain",
            "flask-plain"
        ],
        preview: "/img/maskdetect/maskdetect-preview.png",
        buttons: [
            {
                label: "Demo",
                link: "https://fmd.jozho.space/",
                note: "โปรดใช้รูปที่เห็นใบหน้าชัด เนื่องจากโมเดลมีขนาดเล็ก"
            },
            {label: "Download Model (.pt)", link: "https://cdn.jozho.space/src/fmd/model.pt"},
        ],
        codes: [
            {path: 'example-code/mask.txt', lang: 'python'},
        ]
    },
    {
        urlName: "j-built",
        name: "J-Built",
        description: "เว็บไซต์โชว์ผลงานของผู้รับเหมาก่อสร้าง พร้อมระบบ Dashboard ที่จัดการผลงานได้ด้วยตนเอง",
        stack: [
            "react-original",
            "nodejs-plain",
            "tailwindcss-plain",
            "mongodb-plain"
        ],
        preview: "/img/j-built/preview.png",
        images: [
            "/img/j-built/view-project.png",
            "/img/j-built/full-gallery.png",
            "/img/j-built/dashboard.png",
            "/img/j-built/edit.png",
            "/img/j-built/create.png",
        ],
        buttons: [
            { label: "Website", link: "https://j-built.design/" },
        ],
    },
    {
        urlName: "sy-perfume",
        name: "SY-Perfume Dashboard",
        description: "เว็บแอปพลิเคชันเว็บที่ออกแบบมาเพื่อการดำเนินการทางการเงินภายในบริษัทน้ำหอม",
        stack: [
            "react-original",
            "nodejs-plain",
            "tailwindcss-plain",
            "mysql-plain"
        ],
        preview: "/img/sy-perfume/preview.png",
        images: [
            "/img/sy-perfume/dashboard-1.png",
            "/img/sy-perfume/dashboard-2.png",
            "/img/sy-perfume/users.png",
            "/img/sy-perfume/employees.png",
            "/img/sy-perfume/order-history.png",
            "/img/sy-perfume/order-detail.png",
            "/img/sy-perfume/create-order.png",
            "/img/sy-perfume/shops.png",
            "/img/sy-perfume/products.png",
        ],
    },
    {
        urlName: "userfy",
        name: "UserFY",
        description:
            "แอปพลิเคชันสำหรับการค้นหาบุคคลที่มีทักษะและศักยภาพในการทำงานในองค์กร",
        features: [
            "ค้นหาบุคคลที่ต้องการจ้างงานในพื้นที่ (ค้นหาตามจังหวัด)",
            "ค้นหาบุคคลทั้งหมดในแอปพลิเคชัน"
        ],
        stack: ["dart-plain", "flutter-plain", "php-plain", "mysql-plain"],
        preview: "/img/userfy/userfy-preview.jpg",
        buttons: [{label: "Download Source (.zip)", link: "https://cdn.jozho.space/src/UserFY/UserFY.rar"}],
        codes: [
            {path: 'example-code/userfy-1.txt', lang: 'dart'},
            {path: 'example-code/userfy-2.txt', lang: 'dart'},
            {path: 'example-code/userfy-3.txt', lang: 'dart'},
        ]
    },
    {
        urlName: "gpacal",
        name: "GPA Calculator",
        description:
            "เว็บไซต์ที่ช่วยในการคำนวณค่าเกรดเฉลี่ยของนักเรียนหรือนักศึกษาโดยพิจารณาคะแนนและหน่วยกิตที่ได้รับในแต่ละวิชา",
        features: ["คำนวณเกรดเฉลี่ยด้วยหน่วยกิต", "สามารถบันทึเป็นตาราง Excel / CSV"],
        stack: [
            "html5-plain",
            "bootstrap-plain",
            "javascript-plain",
            "jquery-plain"
        ],
        preview: "/img/gpacal/gpacal-preview.png",
        buttons: [
            {label: "Demo", link: "https://cdn.jozho.space/web-archive/gpa-calculator/"},
            {label: "Github", link: "https://github.com/JozhoX/gpa-calculator"},
        ],
        codes: [
            {path: 'example-code/gpa.txt', lang: 'javascript'},
        ]
    },
];

export default ProjectData;