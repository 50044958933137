import {useState} from "react";
import {FaArrowUp} from "react-icons/fa";

const ScrollUpButton = () => {
    const [visible, setVisible] = useState(false);

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    window.addEventListener('scroll', toggleVisible);

    return (
        <div className="fixed z-10 bottom-4 right-4" style={{display: visible ? 'inline' : 'none'}} >
            <button
                onClick={scrollToTop}
                className="btn btn-ghost btn-circle text-white bg-gradient-to-r from-fuchsia-600 to-purple-600 hover:scale-110">
                <FaArrowUp/>
            </button>
        </div>
    )
}

export default ScrollUpButton;