import ProjectData from "../data/ProjectData";
import ProjectCard from "../ProjectCard";

export default function ProjectSection() {
    return (
        <section id="project" className="py-12 lg:px-5">
            <div className="flex justify-center mb-10">
                <h1 className="text-5xl font-bold bg-gradient-to-r from-fuchsia-500 to-pink-500 text-transparent bg-clip-text inline-block pb-1.5">Projects</h1>
            </div>
            <div className="flex flex-wrap gap-8 justify-center">
                {ProjectData.slice().filter((project) => project.preview).map((project, index) => (
                    <ProjectCard key={index} pos={index} data={project} />
                ))}
            </div>
        </section>
    )
}