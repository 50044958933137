import React, { useState, useEffect } from 'react';
import {atomOneDark, CopyBlock} from "react-code-blocks";
import Loading from "../pages/Loading";

function CodeBlock({ lang, codePath }) {
    const [code, setCode] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`/${codePath}`);
                const data = await response.text();
                setCode(data);
            } catch (error) {
                console.error('Error fetching file:', error);
            }
        };
        fetchData();
    }, [codePath]);

    return (
        <div className="mx-5">
            {code && (
                <CopyBlock
                    text={code}
                    language={lang}
                    showLineNumbers={true}
                    startingLineNumber={1}
                    wrapLines={true}
                    theme={atomOneDark}
                    codeBlock
                />
            )}
        </div>
    );
}

export default CodeBlock;
