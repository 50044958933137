import React from "react";
import {useNavigate} from "react-router-dom";

export default function ProjectCard({data: project, pos: index}) {
    const navigate = useNavigate();

    return (
        // <div className="relative group overflow-hidden rounded-box max-w-screen-sm xl:max-w-screen-md h-fit my-auto">
        //     <div>
        //         {project.preview && (
        //             <Image
        //                 src={project.preview}
        //                 fallbackSrc="https://placehold.co/600x300/"
        //                 className="object-cover w-full max-h-96 transition-transform transform group-hover:scale-110 duration-500"
        //                 loading="lazy"
        //             />
        //         )}
        //         <button
        //             disabled={!project.urlName}
        //             onClick={() => navigate(`/project/${project.urlName}`)}
        //             className="absolute scale-105 inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 bg-black bg-opacity-50 transition duration-300">
        //             <div className="mx-auto text-center space-x-2 space-y-2 px-1">
        //                 <h2 className="text-3xl font-bold">{project.name}</h2>
        //             </div>
        //         </button>
        //     </div>
        //     <div className="absolute bottom-4 right-4 text-white flex items-center bg-gray-800 px-2 py-1 rounded-box">
        //         <GiClick className="text-2xl mr-1.5"/>
        //         <span
        //             className="text-sm font-bold bg-gradient-to-tr from-cyan-400 to-blue-300 text-transparent bg-clip-text">Click anywhere to learn more</span>
        //     </div>
        // </div>

        // <div className="card w-96 bg-zinc-900 shadow-xl">
        //     <figure className="w-full h-fit overflow-hidden flex items-center justify-center px-6 pt-6">
        //         <img
        //             className="max-w-full max-h-full object-contain rounded"
        //             src={project.preview}
        //             alt="Preview"
        //         />
        //     </figure>
        //     <div className="card-body">
        //         <h2 className="card-title">{project.name}</h2>
        //         <p>{project.description}</p>
        //         {/* Learn More */}
        //         {project.urlName && (
        //             <div className="card-actions justify-end mt-4">
        //                 <button
        //                     onClick={() => navigate(`/project/${project.urlName}`)}
        //                     className="link link-hover font-semibold text-lg bg-gradient-to-r from-cyan-500 to-blue-500 text-transparent bg-clip-text">
        //                     Learn More >
        //                 </button>
        //             </div>
        //         )}
        //     </div>
        // </div>

        <div className="hero mb-10 lg:mb-20">
            <div className={`hero-content px-5 flex-col lg:flex-row-reverse`}>
                <img
                    loading="lazy"
                    src={project.preview}
                    className={`max-w-xs lg:max-w-screen-sm object-contain rounded-lg shadow-2xl ${index % 2 === 0 ? "lg:mr-5" : "lg:ml-5"}`}/>
                <div>
                    <h1 className="text-4xl font-bold">{project.name}</h1>
                    {project.deprecated && (
                        <p className="text-yellow-400">Project Discontinued</p>
                    )}
                    <p className="pt-2.5 pb-6 text-lg">{project.description}</p>
                    <button
                        onClick={() => navigate(`/project/${project.urlName}`)}
                        className="link link-hover font-semibold text-lg bg-gradient-to-r from-cyan-500 to-blue-500 text-transparent bg-clip-text">
                        Learn More >
                    </button>
                </div>
            </div>
        </div>
    );
}