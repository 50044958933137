import {FaGithub} from "react-icons/fa";
import {FaInstagram} from "react-icons/fa6";

export default function FooterBar() {
    return (
        <footer className="footer footer-center p-10 bg-zinc-950 text-white">
            <nav>
                <div className="grid grid-flow-col gap-4">
                    <a href={"https://github.com/JozhoX"} target={"_blank"} rel={"noreferrer"}>
                        <FaGithub className="w-8 h-8"/>
                    </a>
                    <a href={"https://instagram.com/__jozho__"} target={"_blank"} rel={"noreferrer"}>
                        <FaInstagram className="w-8 h-8"/>
                    </a>
                </div>
            </nav>
        </footer>
    )
}