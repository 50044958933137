import React from "react";
import WelcomeSection from "../components/section/WelcomeSection";
import ProjectSection from "../components/section/ProjectSection";
import ProfileSection from "../components/section/ProfileSection";

export default function Home() {
    return (
        <div className="hero min-h-screen bg-no-repeat bg-cover bg-fixed" style={{backgroundImage: "url('/background.svg')"}}>
            <div className="hero-overlay bg-opacity-0"></div>
            <div className="hero-content text-neutral-content p-0">
                <div>
                    <WelcomeSection/>
                    <ProjectSection/>
                </div>
            </div>
        </div>
        // <>
        //     <WelcomeSection/>
        //     <ProfileSection/>
        //     <ProjectSection/>
        // </>
    )
}